export const Routing = {
  Home: { path: "/" },

  //customer
  CustomerHome: { path: "/home" },
  Signup: { path: "/signup" },
  Signin: { path: "/signin" },
  ProductList: { path: "/products/:category" },
  ProductDetail: { path: "/product/:sku" },
  Cart: { path: "/cart" },
  Checkout: { path: "/checkout" },
  Confirmation: { path: "/confirmation/:id" },
  OrderDetail: { path: "/orderdetail/:id" },
  Wishlist: { path: "/wishlist" },
  UserProfile: { path: "/profile" },
  OrderHistory: { path: "/history" },
};
