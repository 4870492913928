export const fontSize = {
  sm3: "0.795rem",
  sm2: "0.875rem",
  sm1: "0.9375rem",
  base: "16px",
  lg1: "1.125rem",
  lg2: "1.25rem",
  lg3: "1.138rem",
  lg4: "2.5rem",
  lg5: "3.75rem",
};

export const fontSizeMobile = {
  sm3: "0.75rem",
  sm2: "0.875rem",
  sm1: "0.9375rem",
  base: "16px",
  lg1: "1.125rem",
  lg2: "1.25rem",
  lg3: "1.25rem",
  lg4: "2rem",
  lg5: "3rem",
};

export const fontScale = {
  scale_b7: "1.625rem",
  scale_b6: "1.383rem",
  scale_b5: "1.296rem",
  scale_b4: "1.215rem",
  scale_b3: "1.138rem",
  scale_b2: "1.067rem",
  scale_1: "1rem",
  scale_s2: ".9375rem",
  scale_s3: ".875rem",
  scale_s4: "0.815rem",
  scale_s5: "0.65rem",
};

export const lineHeight = {
  sm4: "1rem",
  sm3: "1.25rem",
  sm2: "1.4rem",
  sm1: "1.625rem",
  base: "1.625rem",
  lg1: "1.725rem",
  lg2: "2rem",
  lg3: "2.15rem",
  lg4: "2.5rem",
  lg5: "3.75rem",
};

export const lineHeightMobile = {
  sm3: "1.25rem",
  sm2: "1.4rem",
  sm1: "1.625rem",
  base: "1.625rem",
  lg1: "1.725rem",
  lg2: "2rem",
  lg3: "1.875rem",
  lg4: "2rem",
  lg5: "3rem",
};

export const fontHeight = {
  body: "1.5rem",
};

export const typeScaleMobile = {
  header1: "2rem",
  header2: "1.5rem",
  header3: "1.25rem",
  header4: "1rem",
  header5: ".875rem",
  header6: ".75rem",
  body: "1rem",
  sbody: ".9125rem",
  helper: "0.75rem",
  caption: "0.64rem",
};
